<bit-simple-dialog>
  <span bitDialogTitle>
    {{ (permanent ? "permanentlyDeleteSelected" : "deleteSelected") | i18n }}
  </span>
  <span bitDialogContent>
    <ng-container *ngIf="!permanent">
      <span *ngIf="cipherIds?.length">
        {{ "deleteSelectedItemsDesc" | i18n: cipherIds.length }}
      </span>
      <span *ngIf="collectionIds?.length">
        {{ "deleteSelectedCollectionsDesc" | i18n: collectionIds.length }}
      </span>
      {{ "deleteSelectedConfirmation" | i18n }}
    </ng-container>
    <ng-container *ngIf="permanent">
      {{ "permanentlyDeleteSelectedItemsDesc" | i18n: cipherIds.length }}
    </ng-container>
  </span>
  <div bitDialogFooter class="tw-flex tw-flex-row tw-gap-2">
    <button bitButton type="submit" buttonType="danger" [bitAction]="submit">
      {{ (permanent ? "permanentlyDelete" : "delete") | i18n }}
    </button>
    <button bitButton type="button" (click)="cancel()">{{ "cancel" | i18n }}</button>
  </div>
</bit-simple-dialog>
