<div class="tabbed-nav d-flex flex-column" *ngIf="!selfHosted">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [routerLink]="subscriptionRoute" routerLinkActive="active">
        {{ "subscription" | i18n }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="payment-method" routerLinkActive="active">
        {{ "paymentMethod" | i18n }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="billing-history" routerLinkActive="active">
        {{ "billingHistory" | i18n }}
      </a>
    </li>
  </ul>
</div>
<router-outlet></router-outlet>
