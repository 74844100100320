<ng-template [ngIf]="isEnabled">
  <button
    type="button"
    bitIconButton="bwi bwi-fw bwi-filter"
    [bitMenuTriggerFor]="content?.menu"
    [buttonType]="buttonType"
    [attr.aria-label]="'switchProducts' | i18n"
  ></button>
  <product-switcher-content #content></product-switcher-content>
</ng-template>
