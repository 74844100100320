<!-- Please remove this disable statement when editing this file! -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<div class="container page-content">
  <div class="tw-mb-4 tw-flex">
    <h1>{{ "groups" | i18n }}</h1>
    <div class="tw-ml-auto tw-flex tw-items-center">
      <div class="tw-mr-2">
        <label class="sr-only">{{ "search" | i18n }}</label>
        <div class="tw-flex tw-items-center">
          <i class="bwi bwi-search bwi-fw tw-z-20 -tw-mr-7 tw-text-muted" aria-hidden="true"></i>
          <input
            bitInput
            type="search"
            placeholder="{{ 'searchGroups' | i18n }}"
            class="tw-rounded-l tw-pl-9"
            [(ngModel)]="searchText"
          />
        </div>
      </div>
      <button bitButton type="button" buttonType="primary" (click)="add()">
        <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
        {{ "newGroup" | i18n }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="sr-only">{{ "loading" | i18n }}</span>
  </ng-container>
  <ng-container *ngIf="!loading && visibleGroups">
    <p *ngIf="!visibleGroups.length">{{ "noGroupsInList" | i18n }}</p>
    <bit-table
      *ngIf="visibleGroups.length"
      infinite-scroll
      [infiniteScrollDistance]="1"
      [infiniteScrollDisabled]="!isPaging()"
      (scrolled)="loadMore()"
    >
      <ng-container header>
        <tr>
          <th bitCell class="tw-w-20">
            <input
              type="checkbox"
              bitCheckbox
              class="tw-mr-2"
              (change)="toggleAllVisible($event)"
              id="selectAll"
            />
            <label class="tw-mb-0 !tw-font-bold !tw-text-muted" for="selectAll">{{
              "all" | i18n
            }}</label>
          </th>
          <th bitCell>{{ "name" | i18n }}</th>
          <th bitCell>{{ "collections" | i18n }}</th>
          <th bitCell class="tw-w-10">
            <button
              [bitMenuTriggerFor]="headerMenu"
              type="button"
              bitIconButton="bwi-ellipsis-v"
              size="small"
              appA11yTitle="{{ 'options' | i18n }}"
            ></button>

            <bit-menu #headerMenu>
              <button type="button" bitMenuItem (click)="deleteAllSelected()">
                <span class="tw-text-danger"
                  ><i aria-hidden="true" class="bwi bwi-trash"></i> {{ "delete" | i18n }}</span
                >
              </button>
            </bit-menu>
          </th>
        </tr>
      </ng-container>
      <ng-template body>
        <tr bitRow *ngFor="let g of visibleGroups">
          <td bitCell (click)="check(g)" class="tw-cursor-pointer">
            <input type="checkbox" bitCheckbox [(ngModel)]="g.checked" />
          </td>
          <td bitCell class="tw-cursor-pointer tw-font-bold" (click)="edit(g)">
            <button bitLink>
              {{ g.details.name }}
            </button>
          </td>
          <td bitCell (click)="edit(g, ModalTabType.Collections)" class="tw-cursor-pointer">
            <bit-badge-list
              *ngIf="!g.details.accessAll"
              [items]="g.collectionNames"
              [maxItems]="3"
              badgeType="secondary"
            ></bit-badge-list>
            <span *ngIf="g.details.accessAll">{{ "all" | i18n }}</span>
          </td>
          <td bitCell>
            <button
              [bitMenuTriggerFor]="rowMenu"
              type="button"
              bitIconButton="bwi-ellipsis-v"
              size="small"
              appA11yTitle="{{ 'options' | i18n }}"
            ></button>

            <bit-menu #rowMenu>
              <button type="button" bitMenuItem (click)="edit(g)">
                <i aria-hidden="true" class="bwi bwi-pencil-square"></i> {{ "editInfo" | i18n }}
              </button>
              <button type="button" bitMenuItem (click)="edit(g, ModalTabType.Members)">
                <i aria-hidden="true" class="bwi bwi-user"></i> {{ "members" | i18n }}
              </button>
              <button type="button" bitMenuItem (click)="edit(g, ModalTabType.Collections)">
                <i aria-hidden="true" class="bwi bwi-collection"></i> {{ "collections" | i18n }}
              </button>
              <button type="button" bitMenuItem (click)="delete(g)">
                <span class="tw-text-danger"
                  ><i aria-hidden="true" class="bwi bwi-trash"></i> {{ "delete" | i18n }}</span
                >
              </button>
            </bit-menu>
          </td>
        </tr>
      </ng-template>
    </bit-table>
  </ng-container>
  <ng-template #addEdit></ng-template>
</div>
