<bit-simple-dialog>
  <i bitDialogIcon class="bwi tw-text-3xl" [class]="iconClasses" aria-hidden="true"></i>

  <span bitDialogTitle>{{ title }}</span>

  <div bitDialogContent>{{ content }}</div>

  <div bitDialogFooter class="tw-flex tw-flex-row tw-gap-2">
    <button
      type="button"
      bitButton
      buttonType="primary"
      (click)="dialogRef.close(SimpleDialogCloseType.ACCEPT)"
    >
      {{ acceptButtonText }}
    </button>

    <button
      *ngIf="showCancelButton"
      type="button"
      bitButton
      buttonType="secondary"
      (click)="dialogRef.close(SimpleDialogCloseType.CANCEL)"
    >
      {{ cancelButtonText }}
    </button>
  </div>
</bit-simple-dialog>
