<app-callout type="info" *ngIf="showKeyConnectorInfo">
  {{ "keyConnectorPolicyRestriction" | i18n }}
</app-callout>

<div [formGroup]="data">
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="enabled"
        [formControl]="enabled"
        name="Enabled"
      />
      <label class="form-check-label" for="enabled">{{ "turnOn" | i18n }}</label>
    </div>
  </div>

  <div class="row">
    <div class="col-6 form-group">
      <label for="minComplexity">{{ "minComplexityScore" | i18n }}</label>
      <select
        id="minComplexity"
        name="minComplexity"
        formControlName="minComplexity"
        class="form-control"
      >
        <option *ngFor="let o of passwordScores" [ngValue]="o.value">{{ o.name }}</option>
      </select>
    </div>
    <div class="col-6 form-group">
      <label for="minLength">{{ "minLength" | i18n }}</label>
      <input
        id="minLength"
        class="form-control"
        type="number"
        min="8"
        name="minLength"
        formControlName="minLength"
      />
    </div>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="requireUpper"
      name="requireUpper"
      formControlName="requireUpper"
    />
    <label class="form-check-label" for="requireUpper">A-Z</label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="requireLower"
      name="requireLower"
      formControlName="requireLower"
    />
    <label class="form-check-label" for="requireLower">a-z</label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="requireNumbers"
      name="requireNumbers"
      formControlName="requireNumbers"
    />
    <label class="form-check-label" for="requireNumbers">0-9</label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="requireSpecial"
      name="requireSpecial"
      formControlName="requireSpecial"
    />
    <label class="form-check-label" for="requireSpecial">!@#$%^&amp;*</label>
  </div>
</div>
