<div
  class="tw-mb-4 tw-box-border tw-rounded tw-border tw-border-l-8 tw-border-solid tw-border-secondary-300 tw-bg-background-alt tw-py-3 tw-px-5 tw-leading-5 tw-text-main"
  [ngClass]="calloutClass"
>
  <h3
    class="tw-mt-0 tw-mb-2 tw-text-base tw-font-bold tw-uppercase"
    [ngClass]="headerClass"
    *ngIf="title"
  >
    <i class="bwi {{ icon }}" *ngIf="icon" aria-hidden="true"></i>
    {{ title }}
  </h3>
  <ng-content></ng-content>
</div>
