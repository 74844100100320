<app-navbar></app-navbar>
<div class="org-nav !tw-h-32" *ngIf="organization$ | async as organization">
  <div class="container d-flex">
    <div class="d-flex flex-column">
      <app-organization-switcher
        class="my-auto pl-1"
        [activeOrganization]="organization"
      ></app-organization-switcher>
      <bit-tab-nav-bar class="-tw-mb-px">
        <bit-tab-link *ngIf="canShowVaultTab(organization)" route="vault">{{
          "vault" | i18n
        }}</bit-tab-link>
        <bit-tab-link *ngIf="canShowMembersTab(organization)" route="members">{{
          "members" | i18n
        }}</bit-tab-link>
        <bit-tab-link *ngIf="canShowGroupsTab(organization)" route="groups">{{
          "groups" | i18n
        }}</bit-tab-link>
        <bit-tab-link *ngIf="canShowReportsTab(organization)" route="reporting">
          {{ getReportTabLabel(organization) | i18n }}
        </bit-tab-link>
        <bit-tab-link *ngIf="canShowBillingTab(organization)" route="billing">{{
          "billing" | i18n
        }}</bit-tab-link>
        <bit-tab-link *ngIf="canShowSettingsTab(organization)" route="settings">{{
          "settings" | i18n
        }}</bit-tab-link>
      </bit-tab-nav-bar>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
<app-footer></app-footer>
