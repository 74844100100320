<bit-simple-dialog>
  <span bitDialogTitle>
    {{ "restoreSelected" | i18n }}
  </span>
  <span bitDialogContent>
    {{ "restoreSelectedItemsDesc" | i18n: cipherIds.length }}
  </span>
  <div bitDialogFooter class="tw-flex tw-flex-row tw-gap-2">
    <button bitButton type="submit" buttonType="primary" [bitAction]="submit">
      {{ "restore" | i18n }}
    </button>
    <button bitButton type="button" (click)="cancel()">{{ "cancel" | i18n }}</button>
  </div>
</bit-simple-dialog>
