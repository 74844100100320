<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="2faRecoveryTitle">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="2faRecoveryTitle">
          {{ "twoStepLogin" | i18n }}
          <small>{{ "recoveryCodeTitle" | i18n }}</small>
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <app-two-factor-verify [type]="type" (onAuthed)="auth($event)" *ngIf="!authed">
      </app-two-factor-verify>
      <ng-container *ngIf="authed">
        <div class="modal-body text-center">
          <ng-container *ngIf="code">
            <p>{{ "twoFactorRecoveryYourCode" | i18n }}:</p>
            <code class="text-lg">{{ code }}</code>
          </ng-container>
          <ng-container *ngIf="!code">
            {{ "twoFactorRecoveryNoCode" | i18n }}
          </ng-container>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="print()" *ngIf="code">
            {{ "printCode" | i18n }}
          </button>
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
            {{ "close" | i18n }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
