<div class="tw-mb-4 tw-flex tw-items-start tw-justify-between">
  <div>
    <bit-breadcrumbs *ngIf="activeFilter.collectionBreadcrumbs.length > 0">
      <bit-breadcrumb
        *ngFor="let collection of activeFilter.collectionBreadcrumbs; let first = first"
        [icon]="first ? undefined : 'bwi-collection'"
        (click)="applyCollectionFilter(collection)"
      >
        <!-- First node in the tree is the "Org Name Vault" item. The rest come from user input. -->
        <ng-container *ngIf="first">
          {{ activeOrganizationId | orgNameFromId: (organizations$ | async) }}
          {{ "vault" | i18n | lowercase }}
        </ng-container>
        <ng-container *ngIf="!first">{{ collection.node.name }}</ng-container>
      </bit-breadcrumb>
    </bit-breadcrumbs>
    <h1 class="tw-mb-0 tw-mt-1 tw-flex tw-items-center tw-space-x-2">
      <i
        *ngIf="activeFilter.isCollectionSelected"
        class="bwi bwi-collection"
        aria-hidden="true"
      ></i>
      <span>{{ title }}</span>
      <small #actionSpinner [appApiAction]="actionPromise">
        <ng-container *ngIf="$any(actionSpinner).loading">
          <i
            class="bwi bwi-spinner bwi-spin text-muted"
            title="{{ 'loading' | i18n }}"
            aria-hidden="true"
          ></i>
          <span class="sr-only">{{ "loading" | i18n }}</span>
        </ng-container>
      </small>
    </h1>
  </div>

  <div *ngIf="!activeFilter.isDeleted" class="tw-shrink-0">
    <button type="button" bitButton buttonType="primary" (click)="addCipher()">
      <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
      {{ "newItem" | i18n }}
    </button>
  </div>
</div>
