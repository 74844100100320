<app-callout type="warning">
  {{ "disableSendExemption" | i18n }}
</app-callout>

<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="enabled"
      [formControl]="enabled"
      name="Enabled"
    />
    <label class="form-check-label" for="enabled">{{ "turnOn" | i18n }}</label>
  </div>
</div>
