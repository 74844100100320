<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="userAddEditTitle">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <form
      class="modal-content"
      #form
      (ngSubmit)="submit()"
      [appApiAction]="formPromise"
      ngNativeValidate
    >
      <div class="modal-header">
        <h1 class="modal-title" id="userAddEditTitle">
          <app-premium-badge *ngIf="readOnly"></app-premium-badge>
          {{ title }}
          <small class="text-muted" *ngIf="name">{{ name }}</small>
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="loading">
        <i
          class="bwi bwi-spinner bwi-spin text-muted"
          title="{{ 'loading' | i18n }}"
          aria-hidden="true"
        ></i>
        <span class="sr-only">{{ "loading" | i18n }}</span>
      </div>
      <div class="modal-body" *ngIf="!loading">
        <ng-container *ngIf="!editMode">
          <p>{{ "inviteEmergencyContactDesc" | i18n }}</p>
          <div class="form-group mb-4">
            <label for="email">{{ "email" | i18n }}</label>
            <input
              id="email"
              class="form-control"
              type="text"
              name="Email"
              [(ngModel)]="email"
              required
            />
          </div>
        </ng-container>
        <h3>
          {{ "userAccess" | i18n }}
          <a
            target="_blank"
            rel="noopener"
            appA11yTitle="{{ 'learnMore' | i18n }}"
            href="https://bitwarden.com/help/emergency-access/#user-access"
          >
            <i class="bwi bwi-question-circle" aria-hidden="true"></i>
          </a>
        </h3>
        <div class="form-check mt-2 form-check-block">
          <input
            class="form-check-input"
            type="radio"
            name="userType"
            id="emergencyTypeView"
            [value]="emergencyAccessType.View"
            [(ngModel)]="type"
          />
          <label class="form-check-label" for="emergencyTypeView">
            {{ "view" | i18n }}
            <small>{{ "viewDesc" | i18n }}</small>
          </label>
        </div>
        <div class="form-check mt-2 form-check-block">
          <input
            class="form-check-input"
            type="radio"
            name="userType"
            id="emergencyTypeTakeover"
            [value]="emergencyAccessType.Takeover"
            [(ngModel)]="type"
            [disabled]="readOnly"
          />
          <label class="form-check-label" for="emergencyTypeTakeover">
            {{ "takeover" | i18n }}
            <small>{{ "takeoverDesc" | i18n }}</small>
          </label>
        </div>
        <div class="form-group col-6 mt-4">
          <label for="waitTime">{{ "waitTime" | i18n }}</label>
          <select
            id="waitTime"
            name="waitTime"
            [(ngModel)]="waitTime"
            class="form-control"
            [disabled]="readOnly"
          >
            <option *ngFor="let o of waitTimes" [ngValue]="o.value">{{ o.name }}</option>
          </select>
          <small class="text-muted">{{ "waitTimeDesc" | i18n }}</small>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          buttonType="primary"
          bitButton
          [loading]="loading || form.loading"
          [disabled]="readOnly"
        >
          {{ "save" | i18n }}
        </button>
        <button bitButton buttonType="secondary" type="button" data-dismiss="modal">
          {{ "cancel" | i18n }}
        </button>
        <div class="ml-auto">
          <button
            #deleteBtn
            bitButton
            buttonType="danger"
            type="button"
            (click)="delete()"
            appA11yTitle="{{ 'delete' | i18n }}"
            *ngIf="editMode"
            [disabled]="$any(deleteBtn).loading"
          >
            <i
              class="bwi bwi-trash bwi-lg bwi-fw"
              [hidden]="$any(deleteBtn).loading"
              aria-hidden="true"
            ></i>
            <i
              class="bwi bwi-spinner bwi-spin bwi-lg bwi-fw"
              [hidden]="!$any(deleteBtn).loading"
              title="{{ 'loading' | i18n }}"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
