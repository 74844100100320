<div class="tw-mb-4 tw-flex tw-items-start tw-justify-between">
  <div>
    <bit-breadcrumbs *ngIf="activeFilter.collectionBreadcrumbs.length > 0">
      <bit-breadcrumb
        *ngFor="let collection of activeFilter.collectionBreadcrumbs; let first = first"
        [icon]="first ? undefined : 'bwi-collection'"
        (click)="applyCollectionFilter(collection)"
      >
        <!-- First node in the tree is the "Org Name Vault" item. The rest come from user input. -->
        <ng-container *ngIf="first">
          {{ activeOrganizationId | orgNameFromId: (organizations$ | async) }}
          {{ "vault" | i18n | lowercase }}
        </ng-container>
        <ng-container *ngIf="!first">{{ collection.node.name }}</ng-container>
      </bit-breadcrumb>
    </bit-breadcrumbs>
    <h1 class="tw-mb-0 tw-mt-1 tw-flex tw-items-center tw-space-x-2">
      <i
        *ngIf="activeFilter.isCollectionSelected"
        class="bwi bwi-collection"
        aria-hidden="true"
      ></i>
      <span>{{ title }}</span>
      <ng-container
        *ngIf="activeFilter.isCollectionSelected && !activeFilter.isUnassignedCollectionSelected"
      >
        <button
          bitIconButton="bwi-angle-down"
          [bitMenuTriggerFor]="editCollectionMenu"
          size="small"
          type="button"
          aria-haspopup
        ></button>
        <bit-menu #editCollectionMenu>
          <button
            type="button"
            *ngIf="canEditCollection(activeFilter.selectedCollectionNode.node)"
            bitMenuItem
            (click)="editCollection(activeFilter.selectedCollectionNode.node, 'info')"
          >
            <i class="bwi bwi-fw bwi-pencil-square" aria-hidden="true"></i>
            {{ "editInfo" | i18n }}
          </button>
          <button
            type="button"
            *ngIf="canEditCollection(activeFilter.selectedCollectionNode.node)"
            bitMenuItem
            (click)="editCollection(activeFilter.selectedCollectionNode.node, 'access')"
          >
            <i class="bwi bwi-fw bwi-users" aria-hidden="true"></i>
            {{ "access" | i18n }}
          </button>
          <button
            type="button"
            *ngIf="canDeleteCollection(activeFilter.selectedCollectionNode.node)"
            bitMenuItem
            (click)="deleteCollection(activeFilter.selectedCollectionNode.node)"
          >
            <span class="tw-text-danger">
              <i class="bwi bwi-fw bwi-trash" aria-hidden="true"></i>
              {{ "delete" | i18n }}
            </span>
          </button>
        </bit-menu>
      </ng-container>
      <small #actionSpinner [appApiAction]="actionPromise">
        <ng-container *ngIf="$any(actionSpinner).loading">
          <i
            class="bwi bwi-spinner bwi-spin text-muted"
            title="{{ 'loading' | i18n }}"
            aria-hidden="true"
          ></i>
          <span class="sr-only">{{ "loading" | i18n }}</span>
        </ng-container>
      </small>
    </h1>
  </div>

  <div *ngIf="!activeFilter.isDeleted" class="tw-shrink-0">
    <div *ngIf="organization.canCreateNewCollections" appListDropdown>
      <button
        bitButton
        buttonType="primary"
        type="button"
        [bitMenuTriggerFor]="addOptions"
        id="newItemDropdown"
        appA11yTitle="{{ 'new' | i18n }}"
      >
        {{ "new" | i18n }}<i class="bwi bwi-angle-down tw-ml-2" aria-hidden="true"></i>
      </button>
      <bit-menu #addOptions aria-labelledby="newItemDropdown">
        <button type="button" bitMenuItem (click)="addCipher()">
          <i class="bwi bwi-fw bwi-globe" aria-hidden="true"></i>
          {{ "item" | i18n }}
        </button>
        <button type="button" bitMenuItem (click)="addCollection()">
          <i class="bwi bwi-fw bwi-collection" aria-hidden="true"></i>
          {{ "collection" | i18n }}
        </button>
      </bit-menu>
    </div>
    <button
      *ngIf="!organization.canCreateNewCollections"
      type="button"
      bitButton
      buttonType="primary"
      (click)="addCipher()"
    >
      <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
      {{ "newItem" | i18n }}
    </button>
  </div>
</div>
