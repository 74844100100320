<ng-container *ngIf="!usesKeyConnector">
  <label for="masterPassword">{{ "masterPass" | i18n }}</label>
  <input
    id="masterPassword"
    type="password"
    name="MasterPasswordHash"
    class="form-control"
    [formControl]="secret"
    required
    appAutofocus
    appInputVerbatim
  />
  <small class="form-text text-muted">{{ "confirmIdentity" | i18n }}</small>
</ng-container>
<ng-container *ngIf="usesKeyConnector">
  <div class="form-group">
    <label class="d-block">{{ "sendVerificationCode" | i18n }}</label>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="requestOTP()"
      [disabled]="disableRequestOTP"
    >
      {{ "sendCode" | i18n }}
    </button>
    <span class="ml-2 text-success" role="alert" @sent *ngIf="sentCode">
      <i class="bwi bwi-check-circle" aria-hidden="true"></i>
      {{ "codeSent" | i18n }}
    </span>
  </div>

  <div class="form-group">
    <label for="verificationCode">{{ "verificationCode" | i18n }}</label>
    <input
      id="verificationCode"
      type="input"
      name="verificationCode"
      class="form-control"
      [formControl]="secret"
      required
      appAutofocus
      appInputVerbatim
    />
    <small class="form-text text-muted">{{ "confirmIdentity" | i18n }}</small>
  </div>
</ng-container>
