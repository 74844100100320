<!-- Please remove this disable statement when editing this file! -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<div class="page-header">
  <h1>{{ "myAccount" | i18n }}</h1>
</div>
<app-profile></app-profile>
<ng-container *ngIf="showChangeEmail">
  <div class="secondary-header">
    <h1>{{ "changeEmail" | i18n }}</h1>
  </div>
  <app-change-email></app-change-email>
</ng-container>
<div class="secondary-header text-danger border-0 mb-0">
  <h1>{{ "dangerZone" | i18n }}</h1>
</div>
<div class="card border-danger">
  <div class="card-body">
    <p>{{ "dangerZoneDesc" | i18n }}</p>
    <button bitButton buttonType="danger" (click)="deauthorizeSessions()">
      {{ "deauthorizeSessions" | i18n }}
    </button>
    <button bitButton buttonType="danger" (click)="purgeVault()">
      {{ "purgeVault" | i18n }}
    </button>
    <button bitButton buttonType="danger" (click)="deleteAccount()">
      {{ "deleteAccount" | i18n }}
    </button>
  </div>
</div>
<ng-template #deauthorizeSessionsTemplate></ng-template>
<ng-template #purgeVaultTemplate></ng-template>
<ng-template #deleteAccountTemplate></ng-template>
<ng-template #viewUserApiKeyTemplate></ng-template>
<ng-template #rotateUserApiKeyTemplate></ng-template>
