<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="2faU2fTitle">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="2faU2fTitle">
          {{ "twoStepLogin" | i18n }}
          <small>{{ "webAuthnTitle" | i18n }}</small>
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <app-two-factor-verify
        [organizationId]="organizationId"
        [type]="type"
        (onAuthed)="auth($any($event))"
        *ngIf="!authed"
      >
      </app-two-factor-verify>
      <form
        #form
        (ngSubmit)="submit()"
        [appApiAction]="formPromise"
        ngNativeValidate
        *ngIf="authed"
      >
        <div class="modal-body">
          <app-callout
            type="success"
            title="{{ 'enabled' | i18n }}"
            icon="bwi bwi-check-circle"
            *ngIf="enabled"
          >
            {{ "twoStepLoginProviderEnabled" | i18n }}
          </app-callout>
          <app-callout type="warning">
            <p>{{ "twoFactorWebAuthnWarning" | i18n }}</p>
            <ul class="mb-0">
              <li>{{ "twoFactorWebAuthnSupportWeb" | i18n }}</li>
            </ul>
          </app-callout>
          <img class="float-right ml-5 mfaType7" alt="FIDO2 WebAuthn logo'" />
          <ul class="bwi-ul">
            <li
              *ngFor="let k of keys; let i = index"
              #removeKeyBtn
              [appApiAction]="k.removePromise"
            >
              <i class="bwi bwi-li bwi-key"></i>
              <strong *ngIf="!k.configured || !k.name">{{ "webAuthnkeyX" | i18n: i + 1 }}</strong>
              <strong *ngIf="k.configured && k.name">{{ k.name }}</strong>
              <ng-container *ngIf="k.configured && !$any(removeKeyBtn).loading">
                <ng-container *ngIf="k.migrated">
                  <span>{{ "webAuthnMigrated" | i18n }}</span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="keysConfiguredCount > 1 && k.configured">
                <i
                  class="bwi bwi-spin bwi-spinner text-muted bwi-fw"
                  title="{{ 'loading' | i18n }}"
                  *ngIf="$any(removeKeyBtn).loading"
                  aria-hidden="true"
                ></i>
                -
                <a href="#" appStopClick (click)="remove(k)">{{ "remove" | i18n }}</a>
              </ng-container>
            </li>
          </ul>
          <hr />
          <p>{{ "twoFactorWebAuthnAdd" | i18n }}:</p>
          <ol>
            <li>{{ "twoFactorU2fGiveName" | i18n }}</li>
            <li>{{ "twoFactorU2fPlugInReadKey" | i18n }}</li>
            <li>{{ "twoFactorU2fTouchButton" | i18n }}</li>
            <li>{{ "twoFactorU2fSaveForm" | i18n }}</li>
          </ol>
          <div class="row">
            <div class="form-group col-6">
              <label for="name">{{ "name" | i18n }}</label>
              <input
                id="name"
                type="text"
                name="Name"
                class="form-control"
                [(ngModel)]="name"
                [disabled]="!keyIdAvailable"
              />
            </div>
          </div>
          <button
            type="button"
            (click)="readKey()"
            class="btn btn-outline-secondary mr-2"
            [disabled]="$any(readKeyBtn).loading || webAuthnListening || !keyIdAvailable"
            #readKeyBtn
            [appApiAction]="challengePromise"
          >
            {{ "readKey" | i18n }}
          </button>
          <ng-container *ngIf="$any(readKeyBtn).loading">
            <i class="bwi bwi-spinner bwi-spin text-muted" aria-hidden="true"></i>
          </ng-container>
          <ng-container *ngIf="!$any(readKeyBtn).loading">
            <ng-container *ngIf="webAuthnListening">
              <i class="bwi bwi-spinner bwi-spin text-muted" aria-hidden="true"></i>
              {{ "twoFactorU2fWaiting" | i18n }}...
            </ng-container>
            <ng-container *ngIf="webAuthnResponse">
              <i class="bwi bwi-check-circle text-success" aria-hidden="true"></i>
              {{ "twoFactorU2fClickSave" | i18n }}
            </ng-container>
            <ng-container *ngIf="webAuthnError">
              <i class="bwi bwi-exclamation-triangle text-danger" aria-hidden="true"></i>
              {{ "twoFactorU2fProblemReadingTryAgain" | i18n }}
            </ng-container>
          </ng-container>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="form.loading || !webAuthnResponse"
          >
            <i
              class="bwi bwi-spinner bwi-spin"
              *ngIf="form.loading"
              title="{{ 'loading' | i18n }}"
              aria-hidden="true"
            ></i>
            <span *ngIf="!form.loading">{{ "save" | i18n }}</span>
          </button>
          <button
            #disableBtn
            type="button"
            class="btn btn-outline-secondary btn-submit"
            [disabled]="$any(disableBtn).loading"
            (click)="disable()"
            *ngIf="enabled"
          >
            <i
              class="bwi bwi-spinner bwi-spin"
              title="{{ 'loading' | i18n }}"
              aria-hidden="true"
            ></i>
            <span>{{ "disableAllKeys" | i18n }}</span>
          </button>
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
            {{ "close" | i18n }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
