<!-- Please remove this disable statement when editing this file! -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<div class="container page-content">
  <div class="row">
    <div class="col-3">
      <div class="groupings">
        <div class="content">
          <div class="inner-content">
            <app-organization-vault-filter
              #vaultFilter
              [organization]="organization"
              [activeFilter]="activeFilter"
              (onSearchTextChanged)="filterSearchText($event)"
            ></app-organization-vault-filter>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9">
      <app-org-vault-header
        [activeFilter]="activeFilter"
        (onCollectionChanged)="refreshItems()"
        [actionPromise]="vaultItemsComponent.actionPromise"
        (onAddCipher)="addCipher()"
        [organization]="organization"
      ></app-org-vault-header>
      <app-callout
        type="warning"
        *ngIf="activeFilter.isDeleted"
        icon="bwi bwi-exclamation-triangle"
      >
        {{ trashCleanupWarning }}
      </app-callout>
      <app-org-vault-items
        [activeFilter]="activeFilter"
        [initOrganization]="organization"
        (onCipherClicked)="navigateToCipher($event)"
        (onAttachmentsClicked)="editCipherAttachments($event)"
        (onAddCipher)="addCipher()"
        (onEditCipherCollectionsClicked)="editCipherCollections($event)"
        (onEventsClicked)="viewEvents($event)"
        (onCloneClicked)="cloneCipher($event)"
      >
      </app-org-vault-items>
    </div>
  </div>
</div>
<ng-template #attachments></ng-template>
<ng-template #cipherAddEdit></ng-template>
<ng-template #collections></ng-template>
<ng-template #eventsTemplate></ng-template>
